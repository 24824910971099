.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 1.5em 1em;
  box-sizing: border-box;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.65);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999;

  &.modal-fade {
    animation: modal-fade-in 1s 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  > .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  > .modal-close {
    position: absolute;
    right: 15px;
    top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    padding: 7px;
    box-sizing: border-box;

    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);

    border: 1px solid #c3c0c0;
    border-radius: 50%;

    font-size: 1.25em;
    text-align: center;
    color: #5e5e5e;

    z-index: 999999;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }

    > span {
      position: relative;
      top: 1px;
    }
  }

  > .modal-body {
    position: relative;
    margin: 0 auto;

    max-height: 100%;
    padding: 15px 20px;

    overflow-x: hidden;
    overflow-y: auto;

    background-color: #303030;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;

    color: #c3c0c0;

    z-index: 2;
  }
}

@keyframes modal-fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
