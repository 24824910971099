.controls {
  position: absolute;

  &.pin-top-left {
    top: 0;
    left: 0;

    &.margin {
      margin: 20px 0 0 20px;
    }
  }

  &.pin-top-right {
    top: 0;
    right: 0;

    &.margin {
      margin: 20px 20px 0 0;
    }
  }

  &.pin-bottom-left {
    bottom: 0;
    left: 0;

    &.margin {
      margin: 0 0 20px 20px;
    }
  }

  &.pin-bottom-right {
    bottom: 0;
    right: 0;

    &.margin {
      margin: 0 20px 20px 0;
    }
  }

  button {
    padding: 0.3em 0.5em;

    font-size: 16px;
    color: #ffffff;

    background: rgba(51, 51, 51, 0.6);

    border: 1px solid black;
    border-radius: 5px;

    &:hover {
      background: rgba(51, 51, 51, 0.9);
    }

    &.info {
      background: rgba(0, 0, 255, 0.6);

      &:hover {
        background: rgba(0, 0, 255, 0.7);
      }
    }

    &.success {
      background: rgba(0, 255, 0, 0.6);

      &:hover {
        background: rgba(0, 255, 0, 0.7);
      }
    }

    &.danger {
      background: rgba(255, 0, 0, 0.6);

      &:hover {
        background: rgba(255, 0, 0, 0.7);
      }
    }
  }

  .hidden {
    position: fixed;
    top: 0;
    left: 0;

    width: 2em;
    height: 2em;

    padding: 0;

    border: none;
    outline: none;
    box-shadow: none;

    background: transparent;
  }
}
