#map {
  width: 100%;
  height: 100%;

  background: url('../images/WorldMap_OSRS.png');
  background-position: top -41px left -40px;
  background-repeat: no-repeat;

  table-layout: fixed;
  border-collapse: collapse;

  &.zoomed-in {
    image-rendering: pixelated;
  }

  // chunk coords
  .chunk-coords {
    display: none;
  }

  &.show-coords .chunk-coords {
    position: relative;

    display: block;

    padding: 2px;

    z-index: 1;
  }

  // clue icons and counts
  .chunk-clues-and-counts {
    display: none;

    padding: 2px;

    div {
      position: relative;
      display: none;

      span {
        display: none;

        position: absolute;
        top: 5px;
        left: 10px;
      }
    }
  }

  &.show-clues .chunk-clues-and-counts {
    display: block;

    &:empty {
      display: none;
    }
  }

  &.show-clue-counts .chunk-clues-and-counts span {
    display: block;
  }

  &.show-beginner-clues .chunk-clues-and-counts div.beginner {
    display: inline-block;
  }

  &.show-easy-clues .chunk-clues-and-counts div.easy {
    display: inline-block;
  }

  &.show-medium-clues .chunk-clues-and-counts div.medium {
    display: inline-block;
  }

  &.show-hard-clues .chunk-clues-and-counts div.hard {
    display: inline-block;
  }

  &.show-elite-clues .chunk-clues-and-counts div.elite {
    display: inline-block;
  }

  &.show-master-clues .chunk-clues-and-counts div.master {
    display: inline-block;
  }

  // chunks without clues
  &.highlight-chunks-without-clues tr td.no-clues {
    background: rgba(255, 0, 0, 0.5);

    &:hover {
      background: rgba(139, 0, 0, 0.5);
    }
  }

  &.hide-chunks-without-clues tr td.no-clues {
    background: #000000;

    border: 1px solid #000000;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  // unlocked chunks without clues
  &.has-locked-chunks.highlight-chunks-without-clues
    tr
    td.no-clues:not(.locked),
  &.has-locked-chunks.hide-chunks-without-clues tr td.no-clues:not(.locked) {
    background: none;

    &:hover {
      background: rgba(0, 255, 0, 0.4);
    }
  }

  // table rows & cells
  tr {
    height: 192px;

    &:first-child td {
      border-top: none;
    }

    &:last-child td {
      border-bottom: none;
    }

    td {
      width: 192px;
      box-sizing: border-box;

      border: 0.5px solid rgba(0, 0, 0, 0.6);

      color: #ffffff;
      vertical-align: top;

      z-index: 1;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.locked {
        background: rgba(0, 0, 0, 0.6);

        .chunk-tile > div {
          background-color: transparent;
        }
      }

      &:hover {
        background: rgba(0, 255, 0, 0.4);
      }

      .chunk-tile {
        position: relative;

        width: 100%;
        height: 100%;

        > div {
          display: inline-block;
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

#sidebar-button {
  border-top: none;
  border-left: none;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

#sidebar {
  position: relative;

  height: 100vh;

  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;

  z-index: 10;

  form {
    height: calc(100% - 24px);
    overflow-y: auto;

    padding: 12px;

    h1 {
      width: 100%;
      text-align: center;
      margin-block-start: 0;
      font-size: 20px;
    }

    > div:not(:last-child) {
      margin-bottom: 6px;
    }

    .toggle-switch img {
      position: relative;
      top: 7px;
    }
  }
}
