#chunk-modal {
  h1 {
    margin-block-start: 0;
    font-size: 22px;
  }

  > div {
    width: 100%;
    overflow-x: auto;
  }
}
