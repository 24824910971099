.stash-unit-table {
  h2 {
    font-size: 18px;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;

      .stash-unit-type {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .stash-unit-icon {
          width: 36px;
          height: auto;

          padding-right: 4px;
        }
      }

      .chunk-coords {
        color: #2a59bd;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .loading-icon {
    height: 32px;
    width: auto;
  }
}
