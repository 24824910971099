.clue-table {
  h2 {
    font-size: 18px;

    img {
      margin-right: 5px;
    }

    span {
      position: relative;
      top: -6px;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;

      .alternate-chunk {
        color: #2a59bd;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .clue-hint {
    font-style: italic;
  }

  .loading-icon {
    height: 32px;
    width: auto;
  }

  .edit-alternate-chunk {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    .right {
      margin-left: 4px;
      display: flex;
    }

    .spacer {
      height: 5px;
    }
  }

  .add-clue {
    margin-top: 10px;
  }
}

.alternate-chunk-tooltip {
  font-size: 16px !important;
}
