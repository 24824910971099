@import '~react-toastify/dist/ReactToastify.css';

@import './Utils.scss';
@import './components/ChunkModal.scss';
@import './components/ClueTable.scss';
@import './components/InfoModal.scss';
@import './components/Map.scss';
@import './components/Modal.scss';
@import './components/SearchModal.scss';
@import './components/StashUnitTable.scss';
@import './components/forms/ToggleSwitch.scss';

#root,
body,
html {
  width: 100%;
  height: 100%;

  margin: 0;

  overflow: hidden;

  font-family: Arial, Helvetica, sans-serif;

  background: #000000;

  a {
    color: #ffffff;

    &:visited {
      color: gray;
    }
  }
}
