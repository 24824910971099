#info-modal {
  overflow-y: auto;

  h1 {
    margin-block-start: 0.15em;
  }

  ul {
    margin-block-start: 0.5em;

    li {
      margin-block-start: 0.2em;

      ul {
        margin-block-start: 0;
      }
    }
  }

  p:last-child {
    margin-block-end: 0.3em;
  }
}
